import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import { PrivateLayout } from 'layouts';
import { AppController, AppTheme } from 'containers';
import { SnackbarProvider } from 'notistack';
import { store } from 'reducers';
import { queryClient } from 'services/client';

const App = () => {
  return (
    <ReduxProvider store={store}>
      <SnackbarProvider
        preventDuplicate={false}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ marginTop: 60 }}
      >
        <QueryClientProvider client={queryClient}>
          <AppTheme>
            <AppController>
              <Router>
                <Switch>
                  <Route path='/' component={PrivateLayout} />
                </Switch>
              </Router>
            </AppController>
          </AppTheme>
        </QueryClientProvider>
      </SnackbarProvider>
    </ReduxProvider>
  );
};

export default App;
