import React from 'react';
import { Container } from '@mui/material';
import { McGeeArtist, McGeeCollections, McGeeGallery, McGeeGuide, McGeeHeader, McGeeSales } from './components';

const McGeeLanding = () => {
  return (
    <div className='text-white'>
      <div
        style={{
          minHeight: 800,
          background: `linear-gradient(#F002, #F002), url(${
            require('./assets/background-top.png').default
          }) no-repeat center center / cover`,
        }}
      >
        <Container className='py-12'>
          <div className='flex items-center flex-wrap'>
            <div className='mx-auto'>
              <McGeeHeader />
            </div>
            <img src={require('./assets/banner.png').default} className='mx-auto rounded-lg sm:rounded-none' />
          </div>
        </Container>
      </div>

      <div
        className='bg-cover pb-40'
        style={{
          background: `linear-gradient(#00000020, #00000020), url(${
            require('./assets/background-content.png').default
          })`,
        }}
      >
        <McGeeSales />
        <div className='mb-40' />

        <McGeeCollections />
        <div className='mb-40' />

        <McGeeGallery />
        <div className='mb-40' />

        <McGeeArtist />
        <div className='mb-40' />

        <McGeeGuide />
      </div>
    </div>
  );
};

export default McGeeLanding;
