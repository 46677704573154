import React from 'react';
import { CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import DotsWhiteImage from 'assets/designs/dots-white.png';

const DesignButton = ({ type = 'primary', size = 240, disabled, loading, children, ...props }) => {
  if (disabled) type = 'disabled';
  const color = React.useMemo(() => {
    if (type === 'primary') return '#7B4A00';
    if (type === 'error') return '#F0F0F0';
    if (type === 'disabled') return '#666666';
  }, [type]);

  return (
    <LoadingButton
      variant='text'
      color='secondary'
      className='relative cursor-pointer p-0'
      style={{ clipPath: 'polygon(12px 0, 100% 0, 100% calc(100% - 14px), calc(100% - 12px) 100%, 0 100%, 0 14px)' }}
      disabled={disabled || loading}
      {...props}
    >
      <img src={require(`assets/designs/button-${type}-${size}.svg`).default} />
      <div
        className='absolute inset-0 flex items-center justify-center'
        style={{
          background: `url(${DotsWhiteImage}) no-repeat right bottom`,
          color,
          fontSize: '1rem',
          fontWeight: 700,
          opacity: loading ? 0.75 : 1,
        }}
      >
        {loading && <CircularProgress className='w-5 h-5 mr-2 text-yellow-600' />}
        {children}
      </div>
    </LoadingButton>
  );
};

export default DesignButton;
