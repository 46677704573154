import React from 'react';
import { Link, Grid, Typography } from '@mui/material';
import { Facebook, Instagram, Language, Twitter } from '@mui/icons-material';
import { DesignContainer } from 'components';

const McGeeArtist = () => {
  return (
    <DesignContainer title='About Artist'>
      <Grid container spacing={6}>
        <Grid item md={5} xs={12}>
          <img
            src={require('../assets/who-artist.png').default}
            className='rounded mt-2 mx-auto'
            style={{ width: 420 }}
          />
        </Grid>
        <Grid item md={7} xs={12}>
          <Typography variant='h2' fontFamily='Orbitron' color='primary'>
            American McGee
          </Typography>
          <div className='mt-1 mb-4 -mx-1'>
            {[
              { url: 'https://mysterious.americanmcgee.com', title: 'Official Website', icon: <Language /> },
              { url: 'https://twitter.com/americanmcgee', title: 'Twitter', icon: <Twitter /> },
              { url: 'https://www.facebook.com/americanmcgee', title: 'Facebook', icon: <Facebook /> },
              { url: 'https://www.instagram.com/americanmcgee', title: 'Instagram', icon: <Instagram /> },
            ].map((item, index) => (
              <Link key={index} href={item.url} className='text-gray-300 hover:text-white mx-1' title={item.title}>
                {item.icon}
              </Link>
            ))}
          </div>
          <Typography paragraph color='silver' align='justify'>
            American McGee is an influential figure in the gaming world best known as the designer of his signature game
            American McGee’s Alice and its sequence Alice: Madness Returns, a psychological horror game set in the world
            of the classic “Alice in Wonderland”. His Alice game series have gained worldwide success.
          </Typography>
          <Typography color='silver' align='justify'>
            Mysterious brings to life the design aesthetic previously found only in the virtual works of game designer
            American McGee. Produced in collaboration with his wife, fashion designer Yeni Zhang, Mysterious explores
            themes and narrative elements from American's most beloved fairy-tale inspired creations. It also presents a
            unique collection of clothing, jewelry, and plush toys designed by a team of artists and designers led by
            Yeni.
          </Typography>
        </Grid>
      </Grid>
    </DesignContainer>
  );
};

export default McGeeArtist;
