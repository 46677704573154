import React from 'react';
import { Typography } from '@mui/material';
import { DesignContainer } from 'components';

const McGeeCollections = () => {
  return (
    <DesignContainer title='Collection'>
      {[
        {
          video: require('../assets/checkout-video1.mp4').default,
          image: require('../assets/checkout-item1.png').default,
          name: 'Anxiety Rabbit',
          description: `Whisper all the troubles in the world and then zip them away inside the Anxiety Rabbit! As time passes
          towards the next Full Moon, all those worries will gradually fade away.
          
          Don't toy with the Worry Bunnies after midnight (wait until sunrise the next day)!
          
          🐇 Let your Anxiety Rabbit "rest" from constant "worry face" from time to time.
          🐇 Use the "thank you" pose to give thanks for the little positive things.`,
          color: '#EC794F',
          bgColor: 'rgba(205, 101, 63, 0.7)',
        },
        {
          video: require('../assets/checkout-video2.mp4').default,
          image: require('../assets/checkout-item2.png').default,
          name: 'Goth Rabbit',
          description: `It's midnight and the rain-slick streets are lit by a harvest moon. Goth Rabbit lights a clove from her bow-shaped black purse and walks on 6" stiletto boots towards the Crystal Palace Bowl. Andrew Eldritch belts out "This Corrosion" as the crowd inside goes wild. It's going to be another one of those nights.
          
          Goth Rabbit features permanent midnight black eyeliner, black lace ear bows, a cross-laced corset back, and more internal corrosion than you can shake a whip at.`,
          color: '#926CFF',
          bgColor: '#481298',
        },
      ].map((item, index) => (
        <div key={index} className='flex flex-col md:flex-row md:space-x-12 mb-20'>
          <div className='flex-1'>
            <video src={item.video} autoPlay loop muted controls className='hidden md:block' />
            <img src={item.image} className='md:hidden' />
          </div>
          <div
            style={{ background: `linear-gradient(225deg, ${item.bgColor} -25%, rgba(0, 0, 0, 0) 65%)` }}
            className='gla-border relative flex-1 border-2 p-6 md:px-10 md:py-10'
          >
            <div className='gla-border absolute inset-0 -mx-3 -my-0.5 border-2'>
              <img src={require('assets/designs/arrow-forward.png').default} className='absolute top-10 left-0' />
              <img src={require('assets/designs/arrow-backward.png').default} className='absolute top-10 right-0' />
            </div>

            <Typography fontFamily='Orbitron' color={item.color} className='font-black text-3xl mb-8'>
              {item.name}
            </Typography>
            <div className='whitespace-pre-line text-lg'>{item.description}</div>
          </div>
        </div>
      ))}
    </DesignContainer>
  );
};

export default McGeeCollections;
