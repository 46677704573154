import { Link } from 'react-router-dom';
import { AppBar, Link as MuiLink, Toolbar, Typography } from '@mui/material';
import { privateRoute } from 'routes';

const Footer = () => {
  return (
    <AppBar position='sticky' elevation={0} style={{ backgroundColor: '#232323' }}>
      <Toolbar>
        <Link to={privateRoute.home.path} className='hidden sm:block'>
          <img src={require('assets/icons/logo-spores-invert.svg').default} className='h-8 sm:h-10' />
        </Link>

        <div className='flex flex-1 sm:justify-end justify-center text-gray-200'>
          <MuiLink href='https://spores.app/explore' className='text-gray-200 hover:text-white mr-6'>
            Marketplace
          </MuiLink>
          <MuiLink href='https://launchpad.spores.app' className='text-gray-200 hover:text-white mr-6'>
            Launchpad
          </MuiLink>
          <MuiLink href='https://lootbox.spores.app' className='text-gray-200 hover:text-white mr-0 sm:mr-6'>
            Lootbox
          </MuiLink>

          <Typography className='hidden md:inline'>
            Contact Us:{' '}
            <MuiLink href='mailto:customersupport@spores.app' className='text-gray-200 hover:text-white'>
              customersupport@spores.app
            </MuiLink>
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
