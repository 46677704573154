import React from 'react';
import { Container, Link, Paper } from '@mui/material';
import { DesignButton, DesignContainer } from 'components';

const McGeeSales = () => {
  return (
    <DesignContainer title='Sales'>
      <div className='flex flex-col items-center'>
        <img src={require('../assets/logo-primary.png').default} />
        <div className='font-black text-5xl my-4'>Plushie Dreadfuls</div>
        <Container maxWidth='md' className='px-0'>
          <div className='text-left md:text-center mb-8'>
            A line of broken, stitched, weird, and scary plush toys from the mind of American McGee. And you thought his
            games were messed up... Currently, there are 16 bunnies in the collection inspired by human emotion and
            mental condition, each unique in its own way. The plushie collection derived from Alice's rabbit doll, which
            is Alice's dearest possession that has survived the house fire and held sentimental value to the main
            character of his series.
          </div>
          <div className='flex flex-col md:flex-row justify-center'>
            {[
              {
                title: 'PRESALE: 12/17/2021',
                lines: [
                  'LIMITED QUANTITY: 250 items',
                  'SPECIAL PRICE: 0.09 ETH/item',
                  'Spores VIP applied: Staking SPO',
                ],
                action: <DesignButton disabled>PARTICIPATE</DesignButton>,
              },
            ].map((item, index) => (
              <Paper key={index} style={{ backgroundColor: '#0F1118', width: '418px' }} className='flex-0.5 mb-10 md:mb-0'>
                <div
                  style={{ backgroundColor: '#171717' }}
                  className='gla-border text-center font-black text-xl text-gray-300 border-2 p-6'
                >
                  {item.title}
                </div>
                <div className='text-white p-8 pb-2'>
                  {item.lines.map((line, index) => (
                    <div key={index} className='font-black text-lg mb-6'>
                      {line}
                    </div>
                  ))}
                </div>
                <div className='text-center mb-10'>{item.action}</div>

                <div className='gla-border absolute bottom-0 left-0 right-0 h-8 border-2 border-t-0'></div>
              </Paper>
            ))}
          </div>
        </Container>
      </div>
    </DesignContainer>
  );
};

export default McGeeSales;
