import { Link } from 'react-router-dom';
import { AppBar, Link as MuiLink, Toolbar } from '@mui/material';
import { privateRoute } from 'routes';

const Header = () => {
  return (
    <AppBar position='sticky' elevation={0} style={{ backgroundColor: '#232323' }}>
      <Toolbar>
        <Link to={privateRoute.home.path}>
          <img src={require('assets/icons/logo-spores-invert.svg').default} className='h-8 sm:h-10' />
        </Link>

        <div className='hidden sm:flex flex-1 items-center justify-end'>
          <MuiLink href='https://spores.app/explore' className='text-gray-200 hover:text-white mr-6'>
            Marketplace
          </MuiLink>
          <MuiLink href='https://launchpad.spores.app' className='text-gray-200 hover:text-white mr-6'>
            Launchpad
          </MuiLink>
          <MuiLink href='https://lootbox.spores.app' className='text-gray-200 hover:text-white mr-0'>
            Lootbox
          </MuiLink>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
