import { createSlice } from '@reduxjs/toolkit';

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    isLoggedIn: false,
  },
  reducers: {
    signIn: (state, { payload }) => {
      state = { ...state, ...payload, isLoggedIn: true };
      localStorage.setItem('profile', JSON.stringify(state));
      return state;
    },
    signOut: (state) => {
      state = { isLoggedIn: false };
      localStorage.removeItem('profile');
      return state;
    },
  },
});

export const { signIn, signOut } = profileSlice.actions;

export default profileSlice.reducer;
