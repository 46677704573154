import { Container } from '@mui/material';

import BackgroundHeader from 'assets/designs/container-header.png';
import BackgroundBackground from 'assets/designs/container-background.png';

const DesignContainer = ({ title, children }) => {
  return (
    <Container>
      <div
        className='relative rounded-lg py-14 px-4 md:px-14 mx-0 md:mx-8'
        style={{ background: `linear-gradient(#0009, #0009), url(${BackgroundBackground})` }}
      >
        <div
          className='absolute inset-0 flex justify-center items-center'
          style={{
            height: 48,
            marginTop: -32,
            background: `url(${BackgroundHeader}) no-repeat center center`,
          }}
        >
          <span
            className='font-black text-3xl'
            style={{
              background: 'linear-gradient(180deg, #FFF 7.5%, #FFC977 60%, #C09645 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {title}
          </span>
        </div>
        {children}
      </div>
    </Container>
  );
};

export default DesignContainer;
