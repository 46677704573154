import { DateTime } from 'luxon';
import { BOOMI_END_TIME, BOOMI_START_TIME } from 'env';

export const CampTimer = ({ saleStart = BOOMI_START_TIME, saleEnd = BOOMI_END_TIME } = {}) => {
  const currentTime = DateTime.now();
  const startTime = DateTime.fromISO(saleStart);
  const endTime = DateTime.fromISO(saleEnd);
  const isStarted = startTime < currentTime;
  const isFinished = endTime < currentTime;
  return {
    isStarted,
    isFinished,
    countdown: (isFinished ? currentTime : isStarted ? endTime : startTime)
      .diff(currentTime, ['days', 'hours', 'minutes', 'seconds'])
      .toObject(),
  };
};
