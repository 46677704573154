import React from 'react';
import { Grid } from '@mui/material';
import { DesignContainer } from 'components';
import { LightBox } from 'components';

const McGeeGallery = () => {
  const [isZoom, setIsZoom] = React.useState(0);

  return (
    <DesignContainer title='Image Gallery'>
      <Grid container>
        {[2, 4, 3, 1, 5, 6].map((i, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <img
              src={require(`../assets/image-gallery${i}.png`).default}
              className='cursor-pointer mx-auto'
              onClick={() => setIsZoom(i)}
            />
            {isZoom > 0 && (
              <LightBox image={require(`../assets/image-gallery${isZoom}.png`).default} onClose={() => setIsZoom(0)} />
            )}
          </Grid>
        ))}
        <img />
      </Grid>
    </DesignContainer>
  );
};

export default McGeeGallery;
