import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Typography } from '@mui/material';
import { CountdownFlip, DesignButton } from 'components';
import { CampTimer } from 'utils/campaign';

const McGeeHeader = () => {
  const { sale_start } = useSelector(({ profile }) => profile);
  const [{ isStarted, isFinished, countdown }, setTimer] = React.useState(CampTimer);

  React.useEffect(() => {
    setTimer(() => CampTimer({ saleStart: sale_start }));
  }, [sale_start]);

  return (
    <div className='flex flex-col items-center lg:items-start max-w-2xl my-16'>
      <img src={require('../assets/logo-primary.png').default} className='mb-10' />
      <Typography variant='h1' className='text-center text-6xl sm:text-5xl md:text-7xl mb-20'>
        Plushie Dreadfuls
      </Typography>

      <div className='box-border shadow-none border-transparent my-6' style={{ background: '#0F1212' }}>
        <Typography variant='subtitle1' align='center' className='mb-4' letterSpacing={2}>
          {isFinished ? 'SALE HAS ENDED' : isStarted ? 'SALE ENDS IN' : 'SALE STARTS IN'}
        </Typography>
        <CountdownFlip {...countdown} />
      </div>
      <div className='space-x-6 mt-10'>
        <Link href='https://t.me/SporesOfficial'>
          <DesignButton>JOIN TELEGRAM</DesignButton>
        </Link>
        <Link href='https://discord.com/invite/PunYs7dABP'>
          <DesignButton>JOIN DISCORD</DesignButton>
        </Link>
      </div>
    </div>
  );
};

export default McGeeHeader;
