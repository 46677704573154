import React from 'react';
import { DesignButton, DesignContainer } from 'components';
import { Link, Paper, Typography } from '@mui/material';

const McGeeGuide = () => {
  return (
    <DesignContainer title='How to participate'>
      <Paper style={{ backgroundColor: '#101010' }} className='text-white p-3 sm:p-6 mb-6'>
        <div className='font-black text-xl mb-2'>1. CONNECT WALLET</div>
        <div>
          On visiting Spores Launchpad, you are required to connect your Metamask wallet on Ethereum Network. Please
          ensure that you have sufficient balance in your wallet before taking part in the sales so you will not miss
          any desired item.
        </div>
      </Paper>

      <Paper style={{ backgroundColor: '#101010' }} className='text-white p-3 sm:p-6 mb-6'>
        <div className='font-black text-xl mb-2'>2. STAKE & WHITELIST</div>
        <div className='mb-4'>
          Participants must fill out the form of interest in order to be whitelisted and qualified for VIP Presale.
          Spores will confirm your VIP level along with your entry time & your quota for the sale after we have reviewed
          your application.
        </div>
        <div className='mb-3'>
          Whitelist:{' '}
          <Typography component='span' color='primary' className='font-black'>
            9 Dec to 16 Dec, 2AM UTC
          </Typography>
        </div>
        <table className='text-center mb-6' style={{ backgroundColor: '#232324' }}>
          <thead className='font-black'>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1'>VIP Level</td>
              <td className='border border-gray-700 h-10 w-52 px-1'>Requirement</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1'>VIP 3</td>
              <td className='border border-gray-700 h-10 w-52 px-1'>Top 20 SPO holders</td>
            </tr>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1'>VIP 2</td>
              <td className='border border-gray-700 h-10 w-52 px-1'>Minimum 50k SPO</td>
            </tr>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1'>VIP 1</td>
              <td className='border border-gray-700 h-10 w-52 px-1'>Minimum 10k SPO</td>
            </tr>
          </tbody>
        </table>
        <Link href='https://spores.xperx.ai/american-mcgee-whitelist'>
          <DesignButton>GET VIP ACCESS</DesignButton>
        </Link>
      </Paper>

      <Paper style={{ backgroundColor: '#101010' }} className='text-white p-3 sm:p-6'>
        <div className='font-black text-xl mb-2'>3. PARTICIPATE</div>
        <div className='text-justify mb-2'>
          You can participate in <span className='font-black'>Plushie Dreathfuls Presale</span> as either a public
          customer or a VIP customer. VIP customers can access the sales earlier and purchase NFTs with higher quota as
          well as get the better discount. After all VIP tiers have completed their purchase round, public customers can
          join and make purchases (subject to available inventory).
        </div>
        <div className='mb-6'>
          We are offering{' '}
          <Typography component='span' color='primary' className='font-black'>
            20% discount
          </Typography>{' '}
          if you pay in SPO.
        </div>

        <div className='mb-6'>
          You may get{' '}
          <Typography component='span' color='primary' className='font-black'>
            extra discount
          </Typography>{' '}
          for minimum order based on your VIP level:
        </div>

        <div className='overflow-x-auto'>
          <table className='text-center mb-6' style={{ backgroundColor: '#232324', minWidth: 720 }}>
            <thead className='font-black'>
              <tr>
                <td className='border border-gray-700 h-10 w-52'>VIP Level</td>
                <td className='border border-gray-700 h-10 w-52'>Quota</td>
                <td className='border border-gray-700 h-10 w-52'>Min order for extra discount</td>
                <td className='border border-gray-700 h-10 w-52'>Discount</td>
                <td className='border border-gray-700 h-10 w-52'>Entry time (UTC)</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='border border-gray-700 h-10 w-52'>VIP 3</td>
                <td className='border border-gray-700 h-10 w-52'>80</td>
                <td className='border border-gray-700 h-10 w-52'>40</td>
                <td className='border border-gray-700 h-10 w-52'>15%</td>
                <td className='border border-gray-700 h-10 w-52'>2 AM, 17 Dec</td>
              </tr>
              <tr>
                <td className='border border-gray-700 h-10 w-52'>VIP 2</td>
                <td className='border border-gray-700 h-10 w-52'>40</td>
                <td className='border border-gray-700 h-10 w-52'>20</td>
                <td className='border border-gray-700 h-10 w-52'>10%</td>
                <td className='border border-gray-700 h-10 w-52'>3 AM, 17 Dec</td>
              </tr>
              <tr>
                <td className='border border-gray-700 h-10 w-52'>VIP 1</td>
                <td className='border border-gray-700 h-10 w-52'>20</td>
                <td className='border border-gray-700 h-10 w-52'>10</td>
                <td className='border border-gray-700 h-10 w-52'>5%</td>
                <td className='border border-gray-700 h-10 w-52'>4 AM, 17 Dec</td>
              </tr>
              <tr>
                <td className='border border-gray-700 h-10 w-52'>Public Round</td>
                <td className='border border-gray-700 h-10 w-52'>No limit</td>
                <td className='border border-gray-700 h-10 w-52'>-</td>
                <td className='border border-gray-700 h-10 w-52'>No discount</td>
                <td className='border border-gray-700 h-10 w-52'>5 AM, 17 Dec</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='mb-6'>
          <Typography component='span' color='primary' className='font-black'>
            10% DISCOUNT
          </Typography>{' '}
          within the first 30 minutes of opening (no VIP, no quota)
        </div>
        <DesignButton disabled>PARTICIPATE</DesignButton>
        <div className='mt-6'>
          You can find the most up-to-date information about the{' '}
          <span className='font-black'>Plushie Dreadfuls NFT Collection</span>{' '}
          <Link
            className='hover:underline'
            href='https://medium.com/@spores/spores-x-plushie-dreadfuls-collection-f5fd8c61261b'
          >
            here
          </Link>
          .
        </div>
      </Paper>
    </DesignContainer>
  );
};

export default McGeeGuide;
